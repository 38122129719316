import axios from "axios";

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};
const services = {
  fetchProducts: async (type) => {
    try {
      const response = await axios.get(`/api/admin/products/${type}/get`);
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  },

  deleteProduct: async (productId) => {
    try {
      const response = await axios.delete(
        `/api/admin/products/${productId}/delete`,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting product:", error);
      return [];
    }
  },

  createStaticData: async (lovKey, lovValue) => {
    try {
      const response = await axios.post(
        "/api/admin/staticdata/addUpdate",
        {
          lovKey: lovKey,
          lovValue: lovValue,
        },
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching static data:", error);
      return [];
    }
  },
  deleteStaticData: async (lovKey) => {
    try {
      const response = await axios.delete(
        `/api/admin/staticdata/${lovKey}/delete`,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting static data:", error);
      return [];
    }
  },
  loadStaticData: async () => {
    try {
      const response = await axios.get("/api/admin/staticdata/all/get");
      return response.data;
    } catch (error) {
      console.error("Error fetching static data:", error);
      return [];
    }
  },

  addUpdateProduct: async (data) => {
    try {
      const response = await axios.post(
        "/api/admin/products/addUpdate",
        data,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Error adding/updating product:", error);
      throw error;
    }
  },

  verifyUser: async () => {
    try {
      const response = await axios.get("/api/user/verify", getHeaders());
      return response.data;
    } catch (error) {
      console.error("Error verifying user:", error);
      return false;
    }
  },

  subscribe: async (pushSubscription) => {
    try {
      const response = await axios.post(
        "/api/notification/subscribe",
        pushSubscription,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("Error sending subscription");
      return false;
    }
  },
  sendNotification: async (notification) => {
    try {
      const response = await axios.post(
        "/api/notification/sendNotification",
        notification,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("Error sending notification");
      return false;
    }
  },

  saveBanners: async (banners) => {
    try {
      const response = await axios.post(
        "/api/admin/banners/save",
        banners,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("Error sending notification");
      throw error;
    }
  },

  fetchBanners: async () => {
    try {
      const response = await axios.get("/api/admin/banners/fetch");
      return response.data;
    } catch (error) {
      console.log("Error sending notification");
      throw error;
    }
  },

  saveOrder: async (order) => {
    try {
      const response = await axios.post(
        "/api/admin/orders/save",
        order,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("Error saving order");
      throw error;
    }
  },

  fetchOrders: async () => {
    try {
      const response = await axios.get(
        "/api/admin/orders/fetch",

        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("Error fetching order");
      throw error;
    }
  },

  updateSeenOrder: async (orderId) => {
    try {
      console.log(orderId);
      const response = await axios.put(
        `/api/admin/orders/${orderId}/seen`,
        {},
        getHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("Error updating order");
      throw error;
    }
  },
};

export default services;
